import React from 'react';
import './VideoComponent.css';
import Background from '../../video/video.mp4';
import Typewriter from 'typewriter-effect';
import { useTranslation } from 'react-i18next';

function VideoComponent() {
  const {t, i18n} = useTranslation();
  return  (
    <div className='videoComponent_main'>
       <div className='videoComponent_container'>
         <div className='videoComponent_bg'>
             <video src={Background} autoPlay loop muted type="video/mp4"></video>
         </div>
         <div className="heroContent">
            
          <h1 className="hero-h1-mobile">{t('Wir')}</h1> 
              <div className="heroContent_headline-mobile">
             
             <h1 className="hero-h1-typewirter-mobile"> 
             <Typewriter
               options={{
                     strings: [`${t('Verstehen')}`, `${t('Konzipieren')}`, `${t('Realisieren')}`,`${t('Vermarkten')}`, `${t('Optimieren')}`],
                     autoStart: true,
                     loop: true,
                       }}        
                      /></h1> 
            </div>
            <h1 className="hero-h1-mobile">{t('Webseiten')}</h1>
              <div className="heroContent_headline">
              <h1 className="hero-h1">{t('Wir')}</h1> 
             <h1 className="hero-h1-typewirter"> 
             <Typewriter
            
               options={{
                     strings: [`${t('Verstehen')}`, `${t('Konzipieren')}`,`${t('Realisieren')}`, `${t('Vermarkten')}`, `${t('Optimieren')}`],
                     autoStart: true,
                     loop: true,
                      }}        
                      /></h1>
                      
            <h1 className="hero-h1">{t('Webseiten')}</h1> 
            </div>
          </div>
       </div>
    </div>
  );
}

export default VideoComponent;
