import React, {useState} from 'react';
import {FaPhoneAlt} from 'react-icons/fa';
import {MdEmail} from 'react-icons/md';
import {IoLocationSharp} from 'react-icons/io5';
import {BsClockHistory} from 'react-icons/bs';
import emailjs from "emailjs-com";
import { toast } from 'react-toastify';
import Fade from 'react-reveal/Fade';
import ClipLoader from 'react-spinners/SyncLoader';
import './ContactForm.css';
import { useTranslation } from 'react-i18next';

function ContactForm() {
 
  const {t, i18n} = useTranslation();
    const [surname, setSurname]         = useState("");
    const [lastname, setLastname]       = useState("");
    const [phonenumber, setPhoneNumber] = useState("");
    const [email, setEmail]             = useState("");
    const [message, setMessage]         = useState("");

 
    const [color, setColor] = useState("#660066");
    const [loading, setLoading] = useState(false);


    function sendEmail(e){
        e.preventDefault();
        setLoading(true);
        emailjs.sendForm(
          'service_0ryts48',
          'template_t3ojbi2',
          e.target,
          'user_1ZSWiow3m5LducSCe8rXN'
        ).then(res => {
          toast.success(`Die Email wurde erfolgreich verschickt. Wir werden uns melden!`);
          setSurname('');
          setLastname('');
          setEmail('');
          setPhoneNumber('');
          setMessage('');
          setLoading(false);
        }).catch(err => {
           toast.error(`Da hat etwas nicht geklappt`)
        });
      }

  return (
      <div className='contactForm_main'>
          <section className='contact_section'>
             <div className='contact_bg'>
               <Fade>  
                <h3>{t('Contact-ut')}</h3>
                <h2>{t('Contact')}</h2>
               </Fade>
                <div className='contact_line'>
                   <div></div>
                   <div></div>
                   <div></div>
                </div>
                  
              <Fade>   
              <p className='contact_text'>{t('Contact-text')}</p>
            </Fade>
               
              </div>
             <div className='contact_body'>
               <div className='contact_info'>
                 <Fade top>  
                  <div>
                      <span><FaPhoneAlt className='fas'/></span>
                      <span>{t('Phone')}</span>
                      <span><a href="tel:017676729010">+49 176 76729010</a></span>
                  </div>
                  <div>
                      <span><MdEmail className='fas'/></span>
                      <span>E-mail</span>
                      <span><a href='mailto:honet.contact@gmail.com'>honet.contact@gmail.com</a></span>
                  </div>
                  <div>
                      <span><IoLocationSharp className='fas'/></span>
                      <span>{t('Adress')}</span>
                      <span><a target="_blank" rel='noopener noreferrer'
                      href='https://www.google.com/maps/place/Honet/@52.1336397,7.4008534,15z/data=!4m5!3m4!1s0x0:0xe2906e559cf9dd87!8m2!3d52.1336324!4d7.4008592'>Am Buchenberg 16, 48565 Steinfurt, DE</a></span>
                  </div>
                  <div>
                      <span><BsClockHistory className='fas'/></span>
                      <span>{t('Hours')}</span>
                      <span>{t('24-Hours')}</span>
                  </div>
                  </Fade>
               </div>
               
               <div className='contact_form'>
                 <form onSubmit={sendEmail}>
                    <h1>{t('Contact')}</h1>
                     <div>
                         <input required name="surname" value={surname} onChange={() => setSurname()} type='text' className='contact_form_control' placeholder='First Name'/>
                         <input required name="lastname" value={lastname} onChange={() => setLastname()} type='text' className='contact_form_control' placeholder='Last Name'/>
                     </div>
                     
                     <div>
                         <input required name='email'  value={email} onChange={() => setEmail()} type='email' className='contact_form_control' placeholder='Email'/>
                         <input required name='phone_number' value={phonenumber} onChange={() => setPhoneNumber()} type='text' className='contact_form_control' placeholder='Phone'/>
                     </div>
                     
                     <div>
                         <textarea required name='message' value={message} onChange={() => setMessage()} rows='5' className='contact_form_control' placeholder='Message'/>
                         <input type='submit' className='contact_send_btn'  value="Send"/>
                     </div>
                     
               <ClipLoader  color={color}  size={10} loading={loading}/>
                 </form>
                 
               </div>
               <div>
 
               <div className='map'>
               <Fade bottom>    
               <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9796.149922808429!2d7.4008534!3d52.1336397!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe2906e559cf9dd87!2sHonet!5e0!3m2!1sde!2sde!4v1643503538987!5m2!1sde!2sde" 
                style={{border:0}} width="100%" height="450" frameborder="0" allowfullscreen="" loading="lazy" aria-hidden="false" tabindex="0"></iframe>
                </Fade>
               </div>

                   
               </div>
             </div>
          </section>
      </div>
  );
}

export default ContactForm;
