import React, { useState} from 'react';
import MobileHeader from '../../components/header/mobileheader/MobileHeader';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import ProjectCard from '../../components/cards/projectcard/ProjectCard';
import './Project.css';

function Projcet() {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }
 
  return (
   <>   
    <MobileHeader isOpen={isOpen}  toggle={toggle} />
    <Header toggle={toggle} />
    <div className='project_main'>
    <ProjectCard/>
    </div>
    <Footer/>
   </>
  );
}

export default Projcet;
