import React, { useState } from 'react'
import { Routes, Route, useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import AdminPanel from '../pages/admin/AdminPanel';

const  AdminRoutes =({children, ...rest}) => {
 
    const [ok, setOk] = useState(false);
    const navigate = useNavigate();


    const handleRoute = () => {
        navigate('/');
        toast.error(
            'You are not allowed to go there!'
        )
    }

  return ok ? (
    <Routes>
      <Route path="panel" element={<AdminPanel/>}></Route>
    </Routes>
  ) : (
      <div onClick={() => handleRoute()}>
      </div>    
  );
}

export default AdminRoutes