import  i18n  from "i18next";
import { initReactI18next } from "react-i18next";

import translationDE from './locales/de/translationDE.json';
import translationEN from './locales/en/translationEN.json';
import translationFR from './locales/fr/translationFR.json';
import translationIT from './locales/it/translationIT.json';
import translationSP from './locales/sp/translationSP.json';
import translationRU from './locales/ru/translationRU.json';
import translationCN from './locales/Cn/translationCN.json';
import translationSA from './locales/sa/translationSA.json';

const resources =  {
    de: {
        translation: translationDE
    },
    en: {
        translation: translationEN
    },
    fr: {
        translation: translationFR
    },
    it: {
        translation: translationIT
    },
    sp: {
        translation: translationSP
    },
    ru: {
        translation: translationRU
    },
    cn: {
        translation: translationCN
    },
    sa: {
        translation: translationSA
    },
}

i18n
  .use(initReactI18next)
  .init({
      resources,
      lng: 'de',
      keySeparator: false,
      interpolation: {
          escapeValue: false
      }
  })

export default i18n;