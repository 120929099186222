import React from 'react';
import Fade from 'react-reveal/Fade';
import './ProjectPage.css';

function ProjectPage(props) {
  return (
    <div className='projectpage_main'>
       <section className='projectpage_section'>
          <div className='projectpage_bg'
          style={{background: props.backgroundColor}}></div>
          <div className='projectpage_content'>
              <div className='projectpage_textBox'>
                <Fade left>  
                  <h2>{props.name}</h2>
                  <p>{props.description}</p>
                  <a href={props.visitPage}
                  target="_blank" rel='noopener noreferrer'>Visit</a>
                </Fade>  
              </div>
              <div className='projectpage_imgBx'>
               <Fade right>   
                <img src={props.productImage} alt='project_image'></img>
                </Fade> 
              </div>
          </div>
       </section>
    </div>
 );
}

export default ProjectPage;
