import React from 'react';
import Logo from './../../images/logo-small.png';
import {
  AiOutlineLinkedin, 
  AiOutlineInstagram, 
  AiOutlineFacebook, 
  AiFillTwitterCircle,
  AiOutlineCopyrightCircle} from 'react-icons/ai';
import './Footer.css';
import { animateScroll as scroll } from 'react-scroll';
import { useTranslation } from 'react-i18next';

function Footer() {
  const {t, i18n} = useTranslation();
  const scrollToTop = () => {
    scroll.scrollToTop();
  }

  return (
  <div className='footer_main'>
      <footer>
          <div className='footer_container'>
            <div className='content_footer'>
               <div className='footer_profil'>
                 <div className='footer_logo_area'> 
                   <img src={Logo} alt='Logo' onClick={() => scrollToTop()}></img>
                   <span className='footer_logo_name' onClick={() => scrollToTop()}>Honet</span>
                 </div> 
               <div className='footer_desc_area'>
                  <p>WEB- & APPDEVELOPMENT</p>
               </div>
               <div className='footer_social_media'>
                  <a href="https://www.instagram.com/honet_official/"
                    target="_blank" rel='noopener noreferrer'><AiOutlineLinkedin/></a>
                  <a href="https://www.instagram.com/honet_official/"
                    target="_blank" rel='noopener noreferrer'><AiOutlineInstagram/></a>
                  <a href="https://www.facebook.com/profile.php?id=100074152038239"
                    target="_blank" rel='noopener noreferrer'><AiOutlineFacebook/></a>
                  <a href="https://twitter.com/honet_official"
                    target="_blank" rel='noopener noreferrer'><AiFillTwitterCircle/></a>
               </div>
            </div>
            <div className='footer_service_area'>
               <ul className='footer_service_header'>
                  <li className='footer_service_name'>Services</li>
                  <li><a href='#'>IT Consulting</a></li>
                  <li><a href='#'>Development</a></li>
                  <li><a href='#'>Cloud</a></li>
                  <li><a href='#'>DevOps & Support</a></li>
               </ul>
               <ul className='footer_service_header'>
                  <li className='footer_service_name'>Industries</li>
                  <li><a href='#'>Finance</a></li>
                  <li><a href='#'>Public Sector</a></li>
                  <li><a href='#'>Smart Office</a></li>
                  <li><a href='#'>Retail</a></li>
               </ul>
               <ul className='footer_service_header'>
                  <li className='footer_service_name'>Quik Links</li>
                  <li><a href='#'>About Us</a></li>
                  <li><a href='#'>Blog</a></li>
                  <li><a href='#'>Contact</a></li>
                  <li><a href='/faq'>FAQ</a></li>
               </ul>
            </div>
          </div>
          <hr/>
          <div className='footer_bottom'>
            <div className='footer_copy_right'>
               <AiOutlineCopyrightCircle/>
               <span>2022 Honet</span>
            </div>
            <div className='footer_tou'>
                <a href='/imprint'>{t('Impressum')}</a>
            </div>
          </div> 
        </div>  
      </footer>
  </div>
  );
}

export default Footer;
