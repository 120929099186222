import React from 'react';
import './DataTable.css';

function DataTable() {
  return (
    <div className='datatable_main'>
      <table>
          <thead>
              <tr>
                  <th>Number</th>
                  <th>Company</th>
                  <th>Package</th>
                  <th>Status</th>
                  <th>Data</th>
              </tr>
          </thead>
          <tbody>
              <tr>
                  <td>001</td>
                  <td>SolarCentral</td>
                  <td>Plus</td>
                  <td>
                      <p className='status status-success'>
                      Success
                      </p>
                  </td>
                  <td className='pdf'>PDF</td>
              </tr>
              <tr>
                  <td>002</td>
                  <td>Gerberei</td>
                  <td>Basic</td>
                  <td>
                      <p className='status status-process'>
                      Process
                      </p>
                  </td>
                  <td className='pdf'>PDF</td>
              </tr>


         

          </tbody>
      </table>
    </div>
  )
}

export default DataTable