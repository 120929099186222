import React, { useState } from 'react';
import './Header.css';

import Logo from '../../images/logo-big.png';
import DE from '../../images/flags/de.svg';
import EN from '../../images/flags/us.svg';
import FR from '../../images/flags/fr.svg';
import IT from '../../images/flags/it.svg';
import SP from '../../images/flags/es.svg';
import RU from '../../images/flags/ru.svg';
import CN from '../../images/flags/cn.svg';
import SA from '../../images/flags/sa.svg';

import {HiMenuAlt3} from 'react-icons/hi';
import {BsGlobe} from 'react-icons/bs';
import {AiOutlineCloseCircle} from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import Fade from 'react-reveal/Fade';
import { useNavigate } from 'react-router';
import { animateScroll as scroll } from 'react-scroll';

function Header({toggle}) {
 
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  }

  const changeLanguage = (ln) => {
    return() => {
      i18n.changeLanguage(ln);
    }
  }

  const {t, i18n} = useTranslation();
  const navigate = useNavigate();
 
  const scrollToTopContact = () => {
    scroll.scrollToTop();
  }

 

  return (
 <>   
  <div className='header'>
     <header>
        <Fade left> 
         <a href='/'><img src={Logo} alt='logo'></img></a>
        </Fade> 
         <div className='header_mobileIcon'>
           <Fade bottom>  
            <HiMenuAlt3 style={{color: '#660066'}} onClick={toggle}/>
            <BsGlobe 
              onClick={toggleModal}
              style={{fontSize: 25, color: '#660066', marginLeft: 10}}/>
            
           </Fade>
         </div>
         <ul className='header_navigation'>
           <Fade left>
            <li><a href="/">{t('Header-1')}</a></li>
            <li><a href="/purchase">{t('Header-2')}</a></li>
            <li><a href='/project'>{t('Header-3')}</a></li>
            <li><a href="/contact" onClick={() => scrollToTopContact()}>{t('Header-4')}</a></li>
            <li
            style={{marginLeft: 25, marginTop: 1}}
            >
              <BsGlobe 
              onClick={toggleModal}
              style={{fontSize: 25, color: '#660066'}}/>
            </li>
            </Fade>
         </ul>
     </header>
  </div>

   {
     modal && (
      <div className='header_modal'>
        <div className='header_overlay'>
          <div className='modal_content'>
             <AiOutlineCloseCircle 
             onClick={toggleModal}
             className='modal_close'/>
            <Fade left>
             <div className='modal_content_languages'>
                <img src={DE} alt="german"></img>
                <p onClick={changeLanguage('de')}>DE</p>
             </div>
             <div className='modal_content_languages'>
                <img src={EN} alt="english"></img>
                <p onClick={changeLanguage('en')}>EN</p>
             </div>
             <div className='modal_content_languages'>
                <img src={FR} alt="french"></img>
                <p onClick={changeLanguage('fr')}>FR</p>
             </div>
             <div className='modal_content_languages'>
                <img src={IT} alt="italian"></img>
                <p onClick={changeLanguage('it')}>IT</p>
             </div>
             <div className='modal_content_languages'>
                <img src={SP} alt="spanish"></img>
                <p onClick={changeLanguage('sp')}>SP</p>
             </div>
             {/*
             <div className='modal_content_languages'>
                <img src={RU} alt="russian"></img>
                <p onClick={changeLanguage('ru')}>RU</p>
             </div>
             <div className='modal_content_languages'>
                <img src={CN} alt="chinese"></img>
                <p onClick={changeLanguage('cn')}>CN</p>
             </div>
             <div className='modal_content_languages'>
                <img src={SA} alt="arabian"></img>
                <p onClick={changeLanguage('sa')}>AR</p>
             </div>*/ }
             </Fade>
          </div>
        </div>
      </div>
     )
   }
  </>
  );
}

export default Header;
