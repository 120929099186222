import React from 'react';
import {MdDesignServices} from 'react-icons/md';
import './ServiceCard.css';
import icon1 from '../../../images/cards/icon1.png';
import icon2 from '../../../images/cards/icon2.png';
import icon3 from '../../../images/cards/icon3.png';
import Fade from 'react-reveal/Fade';
import { useTranslation } from 'react-i18next';

function ServiceCard() {
    const {t, i18n} = useTranslation();
  return (
    <div className='serviceCard_main'>
      <section>
      <div className='serviceCard_headline' >  
        <h1 >{t('Service')}</h1>
      </div>
          <div className='serviceCard_row'>
             <Fade bottom>
             <div className='serviceCard_column'>
                <div className='serviceCard_card'>
                    <div className='serviceCard_iconWrapper'>
                        <img src={icon1}></img>
                    </div>
                    <h3>{t('Service-1')}</h3>
                    <p>
                    {t('Service-1-text')}
                    </p>
                </div>
             </div>
             <div className='serviceCard_column'>
                <div className='serviceCard_card'>
                    <div className='serviceCard_iconWrapper'>
                    <img src={icon2}></img>
                    </div>
                    <h3>{t('Service-2')}</h3>
                    <p>
                    {t('Service-2-text')}
                    </p>
                </div>
             </div>
             <div className='serviceCard_column'>
                <div className='serviceCard_card'>
                    <div className='serviceCard_iconWrapper'>
                    <img src={icon3}></img>
                    </div>
                    <h3>{t('Service-3')}</h3>
                    <p>
                    {t('Service-3-text')}
                    </p>
                </div>
             </div>
             </Fade>
          </div>
      </section>
    </div>
  );
}

export default ServiceCard;
