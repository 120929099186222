import React, {useState} from 'react';
import Footer from '../../../components/footer/Footer';
import MobileHeader from '../../../components/header/mobileheader/MobileHeader';
import Header from '../../../components/header/Header';
import './Gerberei.css';
import ProjectPage from '../../../components/projects/ProjectPage';
import PlaceHolder from '../../../images/project/Gerberei.png';
import { useTranslation } from 'react-i18next';


function Gerberei() {
  const [isOpen, setIsOpen] = useState(false)
  const {t, i18n} = useTranslation();

  const toggle = () => {
      setIsOpen(!isOpen)
  }
  return (
    <div>
         <MobileHeader isOpen={isOpen}  toggle={toggle} />
         <Header toggle={toggle} />
         <div className='main_home'>
           <ProjectPage
           name="Gerberei"
           description={t('Gerberei')}
           backgroundColor="#7b6152"
           productImage={PlaceHolder}
           visitPage='https://gerberei-vill.web.app/'/>
         </div>
         <Footer/>
    </div>
  );
}

export default Gerberei;
