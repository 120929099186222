import React from 'react';
import './ProjectCard.css';
import Bettina from '../../../images/project/image.png';
import Gerberei from '../../../images/project/image3.png';
import SolarCentral from '../../../images/project/image2.png';
import Covid from '../../../images/project/covid.png';
import {CgArrowTopRightR} from 'react-icons/cg';
import { useNavigate } from 'react-router';
import { animateScroll as scroll } from 'react-scroll';
import Fade from 'react-reveal/Fade';
import { useTranslation } from 'react-i18next';


function ProjectCard() {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    const scrollUpGerberei = () => {
        navigate('/projects/gerberei');
        scroll.scrollToTop();
    }

    const scrollUpSolar = () => {
        navigate('/projects/solar-central')
        scroll.scrollToTop();
    }

    const scrollUpBettina = () => {
        navigate('/projects/bettina-thunemann');
        scroll.scrollToTop();
    }
  
    const scrollUpCorona = () => {
        navigate('/projects/covid-19-tracker');
        scroll.scrollToTop();
    }
    return (
    <div className='projectCard_main'>
       <div className='projectCard_wrapper'>
           
       <div className='treedbuttons_headline' style={{marginBottom: 100}}>  
        <h1 >{t('Projects')}</h1>
      </div>
           <div className='projectCard_main_content'>
             <Fade left>
               <div className='projectCard_box' >
                   <img src={Gerberei} alt='project_image' ></img>
                   <div className='projectCard_img_text'>
                       <div className='projectCard_content'>
                           
                           <CgArrowTopRightR
                           onClick={() => scrollUpGerberei()}
                           className='projectCard_icon'/>
                       </div>
                   </div>
               </div>
               </Fade>  
               <Fade left>
               <div className='projectCard_box'>
                   <img src={SolarCentral} alt='project_image'></img>
                   <div className='projectCard_img_text'>
                       <div className='projectCard_content'>
                       <CgArrowTopRightR 
                       onClick={() => scrollUpSolar()} 
                       className='projectCard_icon'/>
                       </div>
                   </div>
               </div>
               </Fade>
               <Fade left>
               <div className='projectCard_box'>
                   <img src={Bettina} alt='project_image'></img>
                   <div className='projectCard_img_text'>
                       <div className='projectCard_content'>
                       <CgArrowTopRightR 
                       onClick={() => scrollUpBettina()} 
                       className='projectCard_icon'/>
                       </div>
                   </div>
               </div>
               </Fade>
             {/*
               <Fade left>
               <div className='projectCard_box'>
                   <img src={Covid} alt='project_image'></img>
                   <div className='projectCard_img_text'>
                       <div className='projectCard_content'>
                       <CgArrowTopRightR 
                       onClick={() => scrollUpCorona()} 
                       className='projectCard_icon'/>
                       </div>
                   </div>
               </div>
    </Fade> */}
           </div>
       </div>
    </div>
  );
}

export default ProjectCard;
