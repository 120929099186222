import React from 'react';
import {BiCheck} from 'react-icons/bi';
import {MdCancel} from 'react-icons/md';
import { useNavigate } from 'react-router';
import './PurchaseCard.css';

function PurchaseCard() {
    const navigate = useNavigate();
  return (
    <div className='purchaseCard_main'>
       <div className='purchase_wrapper'>
             <div className='purchase_table purchase_basic'>
                <div className='purchase_head_tab'>
                    <h2>Standard <br/> Package</h2>
                </div>
                <div className='purchase_aj_p'><p>Standard Webseite für Kleinunternehmen</p></div>
                
              {/*  <div className='cross_price'>
                 <h3>€ 599,00</h3>
  </div> */}
                <div className='purchase_price_section'>
                  <div className='purchase_price_area'>
                    <div className='purchase_inner_area'>
                        <span className='purchase_text'>€</span>
                        <span className='purchase_price'>
                          <span
                          style={{fontSize: 20}}>ab</span>599 
                          
                          </span>
                        
                    </div>
                  </div>
                </div>
 
                <div className='purchase_package_name'></div>
                <ul className='purchase_features'>
                  <div className='purchase_btn'>
                    <button onClick={() => navigate('/contact')}>Purchase</button>
                  </div>
                  {/*<p className='purchase_aj_des'>€100 When you renew</p>*/}
                  <li>
                      <span className='purchase_list_name'>Pages</span>
                      <span className='purchase_icon check'>8-12</span>
                  </li>
                  <li>
                      <span className='purchase_list_name'>Contact Formular</span>
                      <span className='purchase_icon check'><BiCheck className='check'/></span>
                  </li>
                  <li>
                      <span className='purchase_list_name'>Hosting</span>
                      <span className='purchase_icon check'><BiCheck className='check'/></span>
                  </li>
                  <li>
                      <span className='purchase_list_name'>Domain</span>
                      <span className='purchase_icon cancel'><MdCancel className='cancel'/></span>
                  </li>

                </ul>
             </div>
             <div className='purchase_table purchase_premium'>
                <div className='purchase_head_tab'>
                    <h2>Plus <br/> Package</h2>
                </div>
                <div className='purchase_aj_p'><p>Optimale Lösung für Unternehmen</p></div>
                {/*<div className='cross_price'>
                 <h3>€ 1.249,00</h3>
  </div> */}
                <div className='purchase_ribbon'><span>BEST VALUE</span></div>
                <div className='purchase_price_section'>
                  <div className='purchase_price_area'>
                    <div className='purchase_inner_area'>
                        <span className='purchase_text'>€</span>
                        <span className='purchase_price'><span
                          style={{fontSize: 20}}>ab</span>1.249 </span>
                    </div>
                  </div>
                </div>
 
                <div className='purchase_package_name'></div>
                <ul className='purchase_features'>
                  <div className='purchase_btn'>
                    <button onClick={() => navigate('/contact')}>Purchase</button>
                  </div>
                  {/*<p className='purchase_aj_des'>€100 When you renew</p>*/}
                  <li>
                      <span className='purchase_list_name'>Pages</span>
                      <span className='purchase_icon check'>18-26</span>
                  </li>
                  <li>
                      <span className='purchase_list_name'>A Bit Backend</span>
                      <span className='purchase_icon check'><BiCheck className='check'/></span>
                  </li>
                  <li>
                      <span className='purchase_list_name'>Hosting</span>
                      <span className='purchase_icon check'><BiCheck className='check'/></span>
                  </li>
                  <li>
                      <span className='purchase_list_name'>SEO Optimaized</span>
                      <span className='purchase_icon check'><BiCheck className='check'/></span>
                  </li>
                  <li>
                      <span className='purchase_list_name'>Domain</span>
                      <span className='purchase_icon cancel'><BiCheck className='check'/></span>
                  </li>
                  <li>
                      <span className='purchase_list_name'>Admin Panel</span>
                      <span className='purchase_icon cancel'><MdCancel className='cancel'/></span>
                  </li>
                </ul>
             </div>
             <div className='purchase_table purchase_ultimate'>
                <div className='purchase_head_tab'>
                    <h2>Premium <br/> Package</h2>
                </div>
                <div className='purchase_aj_p'><p>Perfektes Angebot für Online Shops</p></div>
               {/* <div className='cross_price'> 
                 <h3>€ 3.499,00</h3>
                </div>*/}
                <div className='purchase_price_section'>
                  <div className='purchase_price_area'>
                    <div className='purchase_inner_area'>
                        <span className='purchase_text'>€</span>
                        <span className='purchase_price'><span
                          style={{fontSize: 20}}>ab</span>3.499</span>
                    </div>
                  </div>
                </div>
 
                <div className='purchase_package_name'></div>
                <ul className='purchase_features'>
                  <div className='purchase_btn'>
                    <button onClick={() => navigate('/contact')}>Purchase</button>
                  </div>
                  {/*<p className='purchase_aj_des'>€100 When you renew</p>*/}
                  <li>
                      <span className='purchase_list_name'>Pages</span>
                      <span className='purchase_icon check'><span 
                      style={{fontSize: 30}}>∞</span></span>
                  </li>
                  <li>
                      <span className='purchase_list_name'>Admin Panel</span>
                      <span className='purchase_icon check'><BiCheck className='check'/></span>
                  </li>
                  <li>
                      <span className='purchase_list_name'>Full Stack Web App</span>
                      <span className='purchase_icon check'><BiCheck className='check'/></span>
                  </li>
                  <li>
                      <span className='purchase_list_name'>Hosting</span>
                      <span className='purchase_icon cancel'><BiCheck className='check'/></span>
                  </li>
                  <li>
                      <span className='purchase_list_name'>Domain</span>
                      <span className='purchase_icon cancel'><BiCheck className='check'/></span>
                  </li>
                  <li>
                      <span className='purchase_list_name'>Google Analytics</span>
                      <span className='purchase_icon cancel'><BiCheck className='check'/></span>
                  </li>
                </ul>
             </div>
         </div>
    </div>
  );
}

export default PurchaseCard;
