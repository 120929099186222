import React from 'react';
import './TestimonialCard.css';
import {AiFillStar} from 'react-icons/ai';
import {FaQuoteLeft} from 'react-icons/fa';
import Placeholder from '../../../images/flugzeug.jpg';

function TestimonialCard(props) {
  return (
    <div>
      <div className='testimonials_main'>
           <div className='testimonials_container'>
               <div className='testimonials_inner'>
                  
                  <div className='testimonials_row'>
                    <div className='testimonials_col'>
                      <div className='testimonials_testimonial'>
                          <img src={props.testimonial_image} alt=""></img>
                          <div className='testimonials_name'>{props.testimonial_name}</div>
                          <p> 
                            {props.testimonial_description}
                          </p>
                      </div>
                    </div>
                </div>
            </div>   
        </div>
      </div>
        <div className='testimonial_wrapper'>
           <div className='testimonial_box'>
             <FaQuoteLeft className='testimonial_quote'/>
             <p>{props.testimonial}</p>
            <div className='testimonial_content'>
               <div className='testimonial_info'>
                 <div className='testimonial_name'>{props.name}</div>
                 <div className='testimonial_job'>{props.companyname}</div>
                 <div className='testimonial_stars'>
                   <AiFillStar className='star'/>
                   <AiFillStar className='star'/>
                   <AiFillStar className='star'/>
                   <AiFillStar className='star'/>
                   <AiFillStar className='star'/>
                 </div>
               </div>
               <div className='testimonial_image'>
                   <img src={Placeholder} alt="user_image"/>
               </div>
            </div>
           </div>
  </div> 
    </div>
  )
}

export default TestimonialCard