import React from 'react';
import {AiFillStar} from 'react-icons/ai';
import Placeholder from '../../images/flugzeug.jpg';
import {FaQuoteLeft} from 'react-icons/fa';
import Fade from 'react-reveal/Fade';
import './Testimonials.css';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import TestimonialCard from '../cards/testimonial/TestimonialCard';
import Testi1 from '../../images/testimonial/testi1.png';
import Testi2 from '../../images/testimonial/testi2.png';
import Testi3 from '../../images/testimonial/testi3.png';

function Testimonials() {

  const {t, i18n} = useTranslation();

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed:  800,
    slidesToShow: 1,
    slidesToScroll: 1,
}
 

  return (
    <div className='testimonial_main'>
      <Fade right>  
      <div className='treedbuttons_headline'>  
        <h1>{t('Testimonials')}</h1>
      </div>
          
          <Carousel
          showArrows={true} 
          autoPlay={true}  
          infiniteLoop={true}
          centerMode={false}
          showThumbs={false}
          swipeable={true}
          useKeyboardArrows={true}>
            <div>
              <img 
              className='slider_img'
              src={Testi1}></img>
            </div>
            <div>
              <img
              className='slider_img' 
              src={Testi2}></img>
            </div>
            <div>
              <img 
              className='slider_img'
              src={Testi3}></img>
            </div>
          </Carousel>
       {/*
        <Carousel>
         <div>
          <TestimonialCard
          testimonial="Lorem ipsum dot et nernLorem ipsum dot et nernLorem ipsum dot et nern"
          name="Surname Name"
          companyname="Job | Company"
          /> 
         </div>
         <div>
          <TestimonialCard
          testimonial="Lorem ipsum dot et nernLorem ipsum dot et nernLorem ipsum dot et nern"
          name="Surname Name"
          companyname="Job | Company"
          />
         </div>
         <div>
          <TestimonialCard
          testimonial="Lorem ipsum dot et nernLorem ipsum dot et nernLorem ipsum dot et nern"
          name="Surname Name"
          companyname="Job | Company"
          />
         </div>
         </Carousel>
       */}
        </Fade>
    </div>
  );
}

export default Testimonials;
