import React, {useState} from 'react';
import ContactForm from '../../components/contactForm/ContactForm';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import MobileHeader from '../../components/header/mobileheader/MobileHeader';
import './Contact.css';

function Contact() {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }
  return (
  <div>
      <MobileHeader  isOpen={isOpen}  toggle={toggle} />
      <Header toggle={toggle}/>
      <div className='main_contact'>
        <ContactForm/>
      </div>
      <Footer/>
  </div>);
}

export default Contact;
