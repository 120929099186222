import { useEffect, useState } from "react";

// Get the current Position of the Mouse

export default function useMousePosition() {
    // Get the current Mouse Position
   const [mousePosition, setMousePosition] = useState({ x: null, y: null});

   // Set the Mouse Position to the EventListener then repeat it
   useEffect(() => {
       const mouseMoveHandler = (e) => {
           const { clientX, clientY } = e;
           setMousePosition({ x: clientX, y: clientY});
       };
       document.addEventListener("mousemove", mouseMoveHandler);

       return () => {
           document.removeEventListener("mousemove", mouseMoveHandler);
       }
   }, []);
   
   return mousePosition;
}