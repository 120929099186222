
import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDlgszR-xZZm3tlwSg_A0dX3bm4y-Y2mDE",
    authDomain: "honet-webseite.firebaseapp.com",
    projectId: "honet-webseite",
    storageBucket: "honet-webseite.appspot.com",
    messagingSenderId: "165524169437",
    appId: "1:165524169437:web:48b7781564dc76d4cdf134",
    measurementId: "G-LGD3VTYV7H"
  };

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);