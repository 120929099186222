import React, { useState } from 'react';
import Footer from '../../../components/footer/Footer';
import Header from '../../../components/header/Header';
import MobileHeader from '../../../components/header/mobileheader/MobileHeader';
import ProjectPage from '../../../components/projects/ProjectPage';
import './CoronaTracker.css';

function CoronaTracker() {

    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }
  return (
    <div>
        <MobileHeader/>
        <Header/>
        <div className='main_home'>
          <ProjectPage
          name="Covid-19-Tracker"
          description="Der Coronavirus Tracker der jeden Tag aktualisiert wird. Weltweite Daten um Leute auzuklären."
          backgroundColor="#f5f6fa"
          visitPage='https://covid-19-tracker-honet.web.app/'
          />
        </div>
        <Footer/>
    </div>
  )
}

export default CoronaTracker