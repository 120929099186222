import React, {useState} from 'react';
import Footer from '../../../components/footer/Footer';
import MobileHeader from '../../../components/header/mobileheader/MobileHeader';
import Header from '../../../components/header/Header';
import './SolarCentral.css';
import ProjectPage from '../../../components/projects/ProjectPage';
import PlaceHolder from '../../../images/project/Solar.png';
import { useTranslation } from 'react-i18next';


function SolarCentral() {
  const [isOpen, setIsOpen] = useState(false)
  const {t, i18n} = useTranslation();

  const toggle = () => {
      setIsOpen(!isOpen)
  }
  return (
    <div>
         <MobileHeader isOpen={isOpen}  toggle={toggle} />
         <Header toggle={toggle} />
         <div className='main_home'>
            <ProjectPage
              name="SolarCentral"
              description={t('SolarCentral')}
              backgroundColor="#192d50"
              productImage={PlaceHolder}
              visitPage='https://solarcentral.de/'/>
         </div>
         <Footer/>
    </div>
  );
}

export default SolarCentral;
