import React, {useState} from 'react';
import Footer from '../../../components/footer/Footer';
import MobileHeader from '../../../components/header/mobileheader/MobileHeader';
import Header from '../../../components/header/Header';
import './BettinaThunemann.css';
import ProjectPage from '../../../components/projects/ProjectPage';
import Bettina from '../../../images/project/Bettina.png';
import { useTranslation } from 'react-i18next';

function BettinaThunemann() {
  const [isOpen, setIsOpen] = useState(false)
  const {t, i18n} = useTranslation();

  const toggle = () => {
      setIsOpen(!isOpen)
  }
  return (
    <div>
         <MobileHeader isOpen={isOpen}  toggle={toggle} />
         <Header toggle={toggle} />
         <div className='main_home'>
           <ProjectPage
            name="Coaching"
            description={t('Thunemann')}
            backgroundColor="#b31329"
            productImage={Bettina}
            visitPage='https://bettina-thuenemann.web.app/home'/>
         </div>
         <Footer/>
    </div>
  );
}

export default BettinaThunemann;
