import React, { useState } from 'react';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import MobileHeader from '../../components/header/mobileheader/MobileHeader';
import './Purchase.css';
import PurchaseCard from '../../components/cards/purchasecard/PurchaseCard';
import PurchaseCardExtra from '../../components/cards/purchasecardextra/PurchaseCardExtra';
import Fade from 'react-reveal/Fade';
import Sales25 from '../../images/sales/easter_25.png';
import Sales25Mobile from '../../images/sales/easter_25_mobile.png';

function Purchase() {

    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }
  return (
    <div>
       <MobileHeader isOpen={isOpen} toggle={toggle}/>
       <Header toggle={toggle}/>
       <div className='purchase_main'>
       <Fade top>
         {/*
        <div className='purchase_main_img'>  
         <img src={Sales25} alt=""></img>
         </div>
         <div className="purchase_main_img_mobile"> 
           <img src={Sales25Mobile} alt=""></img>
  </div> */}
        </Fade>  
        <Fade top> 
         <PurchaseCard/>
        </Fade>
        <Fade bottom>
         <PurchaseCardExtra/>
        </Fade>
       </div>
       <Footer/>
    </div>
  );
}

export default Purchase;
