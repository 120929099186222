import React from 'react';
import './CardHomeAbout.css';
import ImageBlue from '../../../images/home/Blue.jpg';
import Fade from 'react-reveal/Fade';
import { useTranslation } from 'react-i18next';

function CardHomeAboutr() {
	const {t, i18n} = useTranslation();
  return (
    <div className="cardhomeabout_main">
       <div class="cardhomeabout_section">
		<div class="cardhomeabout_container">
			<div class="cardhomeabout_contentr-section">
            <div class="cardhomeabout_title">
                  <Fade>  
					<h1
					style={{color: '#3366ff'}}>{t('App-dev')}</h1>
                    </Fade>  
				</div>
				<div class="cardhomeabout_content"> 
                <Fade>
					<h3>{t('App-dev-ut')}</h3>
					<p>{t('App-dev-text')}</p>
                  
               </Fade> 
				{/*	<div class="cardhomeabout_button">
						<a href="">Read More</a>
                </div>*/ } 
				</div>
			</div>
			<div class="cardhomeabout_imager-section">
              <Fade left>   
				<img src={ImageBlue} alt='app_image' style={{borderRadius: 15}}/>
              </Fade>  
			</div>
		</div>
	</div>
    </div>
  );
}

export default CardHomeAboutr;
