import React from 'react';
import './AdminPanel.css';
import Logo from '../../images/logo-small.png';
import {useNavigate} from 'react-router-dom';
import { auth } from '../../firebase';
import { signOut } from '@firebase/auth';
import {AiFillDatabase} from 'react-icons/ai';
import {GiMoneyStack,GiAlarmClock} from 'react-icons/gi';
import {BiLogOut} from 'react-icons/bi';
import DataTable from './datatable/DataTable';

function AdminPanel() {
 
    const navigate = useNavigate();

    const handleLogout = () => {
      signOut(auth);
      navigate('/');
    }

  return (
    <div className='admin_panel_main'>
        <div className='admin_panel_side_menu'>
           <div className='admin_panel_brand_menu'
           onClick={() => navigate('/')}>
           <img src={Logo}></img>
             <h1>Honet</h1>
           </div>
           <ul>
               <li><AiFillDatabase
               style={{marginRight: 10}}/>Data</li>
               <li>
                  <GiMoneyStack
                  style={{marginRight: 10}}/> Finance
               </li>
               <li>
                  <GiAlarmClock
                  style={{marginRight: 10}}/> Clocking
               </li>
               <li>
               </li>
               <li
               onClick={() => handleLogout()}>
                   <BiLogOut
               style={{marginRight: 10}}/>Logout</li>
           </ul>
        </div>
        <div className='admin_panel_container'>
            <div className='admin_panel_header'>
                <div className='admin_panel_nav'>
                   <div className='admin_panel_username'>
                      <p>Rajpreet Singh - CEO</p>
                   </div>
                </div>
            </div>
            <div className='admin_panel_content'>
                <DataTable/>
            </div>
        </div>
    </div>
  )
}

export default AdminPanel