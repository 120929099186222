import React from 'react';
import Logo from '../../images/logo-small.png';
import { useNavigate } from 'react-router';
import './PageNotFound.css';

function PageNotFound() {
  
  const navigate = useNavigate();

  return (
    <div className='pagenotfound_main'>
        <div className='fourzerofour_page_wrap'>
          <div onClick={() => navigate('/')} className='pagenotfound_logo'>
            <img src={Logo} alt='Logo'></img>
            <p>Honet</p>
          </div>
          <h1>
              404
          </h1>
          <div className='fourzerofour_content'>
             <h2>Looks like you have gooten yourself lost</h2>
             <a href='/' className='fourzerofour_button'>GO HOME</a>
          </div>
        </div>
    </div>
  );
}

export default PageNotFound;
