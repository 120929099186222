import React from 'react';
import './MobileHeader.css';
import Logo from '../../../images/logo-small.png';
import styled from 'styled-components';
import {AiOutlineClose} from 'react-icons/ai';
import {useNavigate} from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import { useTranslation } from 'react-i18next';
import { animateScroll as scroll } from 'react-scroll';

function MobileHeader({isOpen, toggle}) {
    const navigate = useNavigate();

    const {t}  = useTranslation();

    const scrollToTopContact = () => {
        scroll.scrollToTop();
      }
    


  return (
  <>
               <MobileHeader_Container
                         isOpen={isOpen} 
                         onClick={toggle}>
            <div className='MobileHeader_Logo'>
                <img src={Logo} alt='logo' onClick={() => navigate('/')}></img>
            </div>                 
            <div className="MobileHeader_Icon" >
               <AiOutlineClose style={{color: '#660066'}} onClick={toggle}/>
            </div>
            <div className="MobileHeader_Wrapper">
               <ul>
                   <Fade bottom>
                    <a href='/'>{t('Header-1')}</a>
                    <a href='/purchase'>{t('Header-2')}</a>
                    <a  href='/project'>{t('Header-3')}</a>
                    <a href='/contact' onClick={() => scrollToTopContact()}>{t('Header-4')}</a>
                   </Fade> 
               </ul>
            </div>
            </MobileHeader_Container>
  </>
  );
}

export default MobileHeader;


export const MobileHeader_Container = styled.div`
position: fixed;
z-index: 9999;
width: 100%;
height: 100%;
background: white;
display: grid;
align-items: center;
top: 0;
left: 0;
transition: 0.3s ease-in-out;
opacity: ${({ isOpen }) => (isOpen ? '100%' : '0%')};
top: ${({ isOpen }) => (isOpen ? '0'  : '-100%')};
`