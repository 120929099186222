import React from 'react';
import {MdOutlineDesignServices} from 'react-icons/md';
import {ImFileText} from 'react-icons/im';
import {BsCardHeading, BsMap } from 'react-icons/bs';
import { BiTimer } from 'react-icons/bi';
import './PurchaseCardExtra.css';

function PurchaseCardExtra() {
  return (
    <div className='purchaseCardExtra_main'>
     <div className='purchaseCardExtra_wrapper'>   
      <div className='purchaseCardExtra_table'>
      <h1>Extra Services</h1>
      <table className='purchaseCardExtra_table'>
          <thead className='purchaseCardExtra_thead'>
              <tr className='purchaseCardExtra_tr'>

              </tr>
          </thead>
          <tbody className='purchaseCardExtra_tbody'>
              <tr className='purchaseCardExtra_tr'>
                  <td><MdOutlineDesignServices className='table_icon'/></td>
                  <td>Logo Design</td>
                  <td>100 €</td>
              </tr>
              <hr className='purchaseCardExtra_horizontal_line'/>
              <tr className='purchaseCardExtra_tr'>
                  <td><ImFileText className='table_icon'/></td>
                  <td>Werbetexte</td>
                  <td>100 €</td>
              </tr>
              <hr className='purchaseCardExtra_horizontal_line'/>
              <tr className='purchaseCardExtra_tr'>
                  <td><BsCardHeading className='table_icon'/></td>
                  <td>Visitenkarte Design</td>
                  <td>120 €</td>
              </tr>
              <hr className='purchaseCardExtra_horizontal_line'/>
              <tr className='purchaseCardExtra_tr'>
                  <td><BsMap className='table_icon'/></td>
                  <td>Flyer Design</td>
                  <td>150 €</td>
              </tr>
              <hr className='purchaseCardExtra_horizontal_line'/>
              <tr className='purchaseCardExtra_tr'>
                  <td><BiTimer className='table_icon'/></td>
                  <td>Express Service</td>
                  <td>80 €</td>
              </tr>
          </tbody>
      </table>
      </div>
      </div>
    </div>
  );
}

export default PurchaseCardExtra;
