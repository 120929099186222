import './App.css';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Home from './pages/home/Home';
import DotRing from './components/dotring/DotRing';
import { useEffect, useState } from 'react';
import Loading from './components/loading/Loading';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Contact from './pages/contact/Contact';
import PageNotFound from './pages/404/PageNotFound';
import SolarCentral from './pages/projects/solarcentral/SolarCentral';
import Gerberei from './pages/projects/gerberei/Gerberei';
import Bettina from './pages/projects/bettinathuneman/BettinaThunemann';
import Imprint from './pages/imprint/Imprint';
import FAQ from './pages/faq/FAQ';
import Purchase from './pages/purchase/Purchase';
import Projcet from './pages/project/Projcet';
import Login from './pages/login/Login';
import AdminPanel from './pages/admin/AdminPanel';
import AdminRoutes from './routes/AdminRoutes';
import CoronaTracker from './pages/projects/coronatracker/CoronaTracker';


function App() {

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() =>  {
      setLoading(false);
    }, 1000)
  }, []);

  return (
  <>
   <DotRing/> 
    <BrowserRouter>     
   <ToastContainer/>
   {
       loading ? (
         <div className='loading_container'>
            {<Loading/>}
         </div>
       ) : ( 
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/contact" element={<Contact/>}></Route>
        <Route path='/purchase' element={<Purchase/>}></Route>
        <Route path="/projects/solar-central" element={<SolarCentral/>}></Route>
        <Route path="/projects/gerberei" element={<Gerberei/>}></Route>
        <Route path="/projects/bettina-thunemann" element={<Bettina/>}></Route>
        <Route path='/projects/covid-19-tracker' element={<CoronaTracker/>}></Route>
        <Route path="/imprint" element={<Imprint/>}></Route>
        <Route path="/faq" element={<FAQ/>}></Route>
        <Route path='/project' element={<Projcet/>}></Route>
        <Route path='/admin' element={<Login/>}></Route>
        <Route path='/admin/panel' element={<AdminPanel/>}></Route>
        <Route path='/admin/*' element={<AdminRoutes/>}></Route>
        <Route path="*" element={<PageNotFound/>}></Route>
      </Routes>
    
      )
    }
    </BrowserRouter>
  </>  
  );
}

export default App;
