import React, {useEffect, useState} from 'react';
import MobileHeader from '../../components/header/mobileheader/MobileHeader';
import Header from '../../components/header/Header';
import './Home.css';
import Footer from '../../components/footer/Footer';
import CookieConsent from 'react-cookie-consent';
import CardHomeAbout from '../../components/cards/cardhomeabout/CardHomeAbout';
import CardHomeAboutr from '../../components/cards/cardhomeabout/CardHomeAboutr';
import ServiceCard from '../../components/cards/servicecard/ServiceCard';

import Testimonials from '../../components/testimonials/Testimonials';
import TreeDButtons from '../../components/3dbutton/3DButtons';
import TextAnimation from '../../components/textanimation/TextAnimation';
import ReactGA from "react-ga";
import { withRouter } from 'react-router-dom';
import Head from 'next/head';
import VideoComponent from '../../components/videoCompenet/VideoComponent';
import ImageBanner from '../../components/imageBanner/ImageBanner';

import WhyImage from '../../images/why-home.jpg';
import WhyMobileImage from '../../images/why-home-mobile.jpg';
import HowImage from '../../images/how-home.jpg';
import HowMobileImage from '../../images/how-home-mobile.jpg';
import Banner from '../../components/banner/Banner';
import {useNavigate} from 'react-router-dom';

import { useTranslation } from 'react-i18next';

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE)

function Home() {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
      setIsOpen(!isOpen)
  }

  useEffect(() =>  {
    ReactGA.pageview(window.location.pathname + window.location.search);
  })
 
  const navigate = useNavigate();
  
  const {t, i18n} = useTranslation();
  const Path = useState(require('../../images/how-home.jpg'));

  return (<div>
   <main> 
     <Head>
       <title>Honet | Home</title>
       <meta httpEquiv='Content-Type' content='text/html; charset=utf-8'></meta>
       <meta name='description' content='Home Page from Honet'></meta>
       <meta name='viewport' content='width=device-width, initial-scale=1'></meta>
     </Head>
   <MobileHeader isOpen={isOpen}  toggle={toggle} />
   <Header toggle={toggle} />
   <div className='main_home'>
        <TextAnimation/>
        <VideoComponent/>
        <CardHomeAbout/>
        <CardHomeAboutr/>
        <ServiceCard/>
        <ImageBanner
         banner_image={HowImage}
         banner_image_mobile={HowMobileImage}
         image_banner_headline='Wie Wir Arbeiten'/>
        <ImageBanner
         banner_image={WhyImage}
         banner_image_mobile={WhyMobileImage}
         image_banner_headline='Warum Honet'/>
         <Banner/>
        <Testimonials/>
        <TreeDButtons/>
   </div>
   
   <CookieConsent 
   debug={true}
   location='bottom'
   style={{ background: '#f2f2f2', textAlign: "left", color: '#000'}}
   buttonStyle={{ color: '#fff', background: '#551a8b', fontSize: '16px'}}
   buttonText="Ok!"
   enableDeclineButton={true}
   onDecline={() => {
     navigate('/imprint');
   }}
   declineButtonText={t('decline-button')}
   declineButtonStyle={{ fontSize: '16px',  background: '#fff', color: '#000'}}
   >
    
    {t('cookies-first')}
   </CookieConsent>
   
   <Footer/>
   </main>
  </div>);
}

export default Home;
