import React from 'react';
import {BsMouse} from 'react-icons/bs';
import { animateScroll as scroll } from 'react-scroll';
import './TextAnimation.css';
import { useTranslation } from 'react-i18next';

function TextAnimation() {
  const {t, i18n} = useTranslation();
  const scrollMorefunciton = () => {
    scroll.scrollMore(850);
  }

  return (
    <div className='textanimation_main'>
     {/*<div className='textanimation_hero_image'>*/} 
        <div className='textanimation_container'>
           <span className='textanimation_text1'>{t('Welcome')}</span>
           <span className='textanimation_text2'>Honet</span>
        </div>
          <BsMouse 
           className='mouse_icon'
           onClick={scrollMorefunciton}/>
      {/*</div>*/} 
    </div>
  );
}

export default TextAnimation;
