import React from 'react';
import './Banner.css';

function Banner() {
  return (
    <div className='banner_main'>
      <div className='banner_main_image'>
          <div className='banner_background'>
            <div className='banner_background_border'>
               <div className='banner_container'>
                  <h1><span>Wir arbeiten International</span></h1>
               </div>
            </div>   
          </div>
        </div>
    </div>
  )
}

export default Banner