import React from 'react';
import Logo from '../../images/logo-big.png';
import { Bars } from  'react-loader-spinner';
import './Loading.css';

function Loading() {
  return( 
    <div className='loading_main'>
        <div className='loading_container'>
           <img src={Logo} alt="Logo"></img>	
        </div>
        <div className="loading_loader"> 
           <Bars 
           color="#660066" 
           height={70} 
           width={70} />
           </div>
    </div>
  );
}

export default Loading;
