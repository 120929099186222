import React from 'react';
import Fade from 'react-reveal/Fade'
import './3DButtons.css';
import { useTranslation } from 'react-i18next';

function TreeDButtons() {
  const {t, i18n} = useTranslation();
  return (
    <div className='treedbuttons_main'>
      <div className='treedbuttons_headline'>  
        <h1>{t('Start')}</h1>
      </div>
      <div className='treedbutton_component'>
      <Fade top> 
        <div className='treedbutton_center'>
           <a href='/contact'><span></span><span></span> Start</a>
        </div>
        </Fade>
      </div>
    </div>
  );
}

export default TreeDButtons;
