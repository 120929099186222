import React from 'react';
import { useState } from 'react';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import MobileHeader from '../../components/header/mobileheader/MobileHeader';
import { QuestionData } from './QuestionData';
import {BsArrowDownShort, BsArrowUpShort} from 'react-icons/bs';
import Fade from 'react-reveal/Fade';
import './FAQ.css';

function FAQ() {
    
    const [clicked, setClicked] = useState(false)
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    const toggleQuestion = index => {
        if(clicked === index) {
            return setClicked(null)
        }
        setClicked(index)
    }

  return (
    <div>
     <MobileHeader isOpen={isOpen}  toggle={toggle} />
     <Header toggle={toggle} />
      <div className='faq_main'>
          <div className='accordian_section'>
              <div className='accordian_container'>
                <div className='treedbuttons_headline'>  
                  <h1 style={{marginTop: 20}}>FAQ</h1>
                 </div>
                  {QuestionData.map((item, index) => {
                      return(
                          <>
                           <div className='accordian_wrap' onClick={() => toggleQuestion(index)} key={index}>
                             <h1>{item.question}</h1>
                             <span>{clicked === index ? 
                             <BsArrowUpShort className='icon_acc'/>
                              :
                              <BsArrowDownShort className='icon_acc'/>}</span>
                           </div>
                           {clicked === index ? (
                            <Fade top>     
                              <div className='accordian_dropdown'>
                                   <p>{item.answer}</p> 
                              </div>
                            </Fade>  
                           ) : null}
                          </>
                      );
                  })}
              </div>
          </div>
      </div>   
     <Footer/>
    </div>
  );
}

export default FAQ;
