import React from 'react';
import './CardHomeAboutr.css';
import ImagePurple from '../../../images/home/Purple.jpg';
import Fade from 'react-reveal/Fade';
import { useTranslation } from 'react-i18next';

function CardHomeAbout() {
	const {t, i18n} = useTranslation();
  return (
    <div className="cardhomeabout_main">
       <div class="cardhomeabout_section">
		<div class="cardhomeabout_container">
			<div class="cardhomeabout_content-section">
			  	
                <div class="cardhomeabout_title">
                  <Fade>  
					<h1 style={{color: '#6e3b9c'}}>{t('Web-dev')}</h1>
                    </Fade>  
				</div>
				<div class="cardhomeabout_content"> 
                <Fade>
					<h3>{t('Web-dev-ut')}</h3>
					<p>{t('Web-dev-text')}</p>
                  
               </Fade> 
				{/*	<div class="cardhomeabout_button">
						<a href="">Read More</a>
                </div>*/ } 
				</div>
			</div>
			<div class="cardhomeabout_image-section">
              <Fade right>  
				<img src={ImagePurple} alt='web_image' style={{borderRadius: 15}}/>
              </Fade>  
			</div>
		</div>
	</div>
    </div>
  );
}

export default CardHomeAbout;
