import React, { useState } from 'react';
import './Login.css';
import Logo from '../../images/logo-big.png';
import {useNavigate} from 'react-router-dom';
import {signInWithEmailAndPassword, sendSignInLinkToEmail} from '@firebase/auth';
import {auth} from '../../firebase';
import {toast} from 'react-toastify';

function Login() {
 
    const navigate = useNavigate();

    const [email , setEmail]      = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading]   = useState(false);
     

    const handleLogin = () =>  {
      setLoading(true);
      try {
        signInWithEmailAndPassword(auth, email, password);
        toast.success(
          `Success Login with this ${email}`
        )
        navigate('/admin/panel');
      } catch (err) {
        toast.error(err);
      }
      setLoading(false);
    }

  return (
    <div className='login_main'>
        <div className='login_logo'>
           <img src={Logo} onClick={() => navigate('/')}></img>
        </div>
       <div className='login_center'>
         { loading ? (
           <h1>Loading</h1>
         ) : (
           <h1>Login - Admin</h1>
         )
         }
         <form onSubmit={() => handleLogin()}>
             <div className='login_txt_field'>
                 <input 
                 type='text'
                 value={email}
                 onChange={(e) => setEmail(e.target.value)} 
                 required></input>
                 <span></span>
                 <label>Email</label>
             </div>
             <div className='login_txt_field'>
                 <input 
                 type='password' 
                 value={password}
                 onChange={(e) => setPassword(e.target.value)}
                 required></input>
                 <span></span>
                 <label>Password</label>
             </div>
             <input type='submit' value="Login"></input>
         </form>
       </div>
    </div>
  )
}

export default Login