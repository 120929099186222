import React from 'react';
import './ImageBanner.css';

function ImageBanner(props) {
  return (
    <div className='image_banner_main'>
      <div 
      className='treedbuttons_headline'
      >
        <h1
        style={{marginBottom: 55}}>{props.image_banner_headline}</h1>
        <img
        className='image_banner_image'
        src={props.banner_image}
        ></img>
        <img
        className='image_banner_mobile_image'
        src={props.banner_image_mobile}></img>
      </div>
    </div>
  )
}

export default ImageBanner